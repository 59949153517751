import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { SpellerContext } from '../contexts/SpellerContext';

const Container = styled.div`
    margin-bottom: 10px;
    text-align: center;
`;

export const SpellerForm: FC = () => {
    const [word, setWord] = useState('');

    const speller = useContext(SpellerContext);

    return (
        <Container>
            <input
                type="text"
                value={word}
                onChange={(e) => {
                    setWord(e.target.value);
                }}
            />{' '}
            <button
                type="button"
                onClick={() => {
                    if (speller) {
                        speller.setWord(word);
                    }
                }}
            >
                Go!
            </button>
        </Container>
    );
};
