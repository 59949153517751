import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { SpellerContext } from '../contexts/SpellerContext';

const alphabet = new Map<string, { thumb: string; photopage: string }>();

alphabet.set('A', {
    thumb: 'https://static.flickr.com/63/214746470_119212be40_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214746470/',
});
alphabet.set('B', {
    thumb: 'https://static.flickr.com/93/214746469_e9f01b4235_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214746469/',
});
alphabet.set('C', {
    thumb: 'https://static.flickr.com/73/214731498_6f8689786c_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214731498/',
});
alphabet.set('D', {
    thumb: 'https://static.flickr.com/65/214731497_7aa686412f_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214731497/',
});
alphabet.set('E', {
    thumb: 'https://static.flickr.com/82/214731495_91936b8d4d_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214731495/',
});
alphabet.set('F', {
    thumb: 'https://static.flickr.com/62/214731494_a306548dec_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214731494/',
});
alphabet.set('G', {
    thumb: 'https://static.flickr.com/57/214731493_6afbe1ad69_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214731493/',
});
alphabet.set('H', {
    thumb: 'https://static.flickr.com/91/214731492_410f52823f_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214731492/',
});
alphabet.set('I', {
    thumb: 'https://static.flickr.com/67/214730280_6326a6d51d_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214730280/',
});
alphabet.set('J', {
    thumb: 'https://static.flickr.com/95/214730279_ab174662b6_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214730279/',
});
alphabet.set('K', {
    thumb: 'https://static.flickr.com/67/214730277_f8e9d5302f_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214730277/',
});
alphabet.set('L', {
    thumb: 'https://static.flickr.com/79/214730275_b825ce027f_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214730275/',
});
alphabet.set('M', {
    thumb: 'https://static.flickr.com/83/214730269_fcd17edef1_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214730269/',
});
alphabet.set('N', {
    thumb: 'https://static.flickr.com/70/214730268_caa0ebfc8e_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214730268/',
});
alphabet.set('O', {
    thumb: 'https://static.flickr.com/94/214727860_a8001774fd_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214727860/',
});
alphabet.set('P', {
    thumb: 'https://static.flickr.com/81/214727858_0b65a535f2_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214727858/',
});
alphabet.set('Q', {
    thumb: 'https://static.flickr.com/81/214727857_68029c53e6_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214727857/',
});
alphabet.set('R', {
    thumb: 'https://static.flickr.com/66/214727855_bc60df1062_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214727855/',
});
alphabet.set('S', {
    thumb: 'https://static.flickr.com/82/214727854_c9ef9b63b3_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214727854/',
});
alphabet.set('T', {
    thumb: 'https://static.flickr.com/59/214727853_ccab5205a2_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214727853/',
});
alphabet.set('U', {
    thumb: 'https://static.flickr.com/69/214725917_1230681657_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214725917/',
});
alphabet.set('V', {
    thumb: 'https://static.flickr.com/76/214725916_359046bf79_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214725916/',
});
alphabet.set('W', {
    thumb: 'https://static.flickr.com/35/214725914_00d9c1e078_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214725914/',
});
alphabet.set('X', {
    thumb: 'https://static.flickr.com/82/214725913_f788ccf13b_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214725913/',
});
alphabet.set('Y', {
    thumb: 'https://static.flickr.com/94/214725912_41f6f0c7e3_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214725912/',
});
alphabet.set('Z', {
    thumb: 'https://static.flickr.com/92/214725909_7b0f4c5225_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/214725909/',
});
alphabet.set('1', {
    thumb: 'https://static.flickr.com/96/216296624_f8f78ca624_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216296624/',
});
alphabet.set('2', {
    thumb: 'https://static.flickr.com/62/216296623_d376619414_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216296623/',
});
alphabet.set('3', {
    thumb: 'https://static.flickr.com/95/216296619_64f4301474_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216296619/',
});
alphabet.set('4', {
    thumb: 'https://static.flickr.com/98/216295710_559d31845d_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216295710/',
});
alphabet.set('5', {
    thumb: 'https://static.flickr.com/94/216295709_0011a1242a_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216295709/',
});
alphabet.set('6', {
    thumb: 'https://static.flickr.com/65/216295706_f7773ad0b9_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216295706/',
});
alphabet.set('7', {
    thumb: 'https://static.flickr.com/70/216295705_f0dd05ab46_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216295705/',
});
alphabet.set('8', {
    thumb: 'https://static.flickr.com/97/216295703_dd1b6cbfec_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216295703/',
});
alphabet.set('9', {
    thumb: 'https://static.flickr.com/61/216295702_4d01d06665_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216295702/',
});
alphabet.set('0', {
    thumb: 'https://static.flickr.com/60/216294037_9ec8bedf2e_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216294037/',
});
alphabet.set('$', {
    thumb: 'https://static.flickr.com/79/216294035_4c58952d14_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216294035/',
});
alphabet.set(';', {
    thumb: 'https://static.flickr.com/59/216294034_2d8aebfcf4_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216294034/',
});
alphabet.set('@', {
    thumb: 'https://static.flickr.com/66/216294033_9174d44075_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216294033/',
});
alphabet.set('?', {
    thumb: 'https://static.flickr.com/83/216294031_b7716dda31_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216294031/',
});
alphabet.set('!', {
    thumb: 'https://static.flickr.com/89/216294030_e9637978dc_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216294030/',
});
alphabet.set('.', {
    thumb: 'https://static.flickr.com/75/216322940_5ca2a9db1b_t.jpg',
    photopage: 'https://www.flickr.com/photos/jawboneradio/216322940/',
});

const Container = styled.div`
    margin: 0px auto;
    max-width: 800px;
`;

const ZombieContainer = styled.div`
    margin: 1rem 0;
    text-align: center;
`;

export const SpellerOutput: FC = () => {
    const speller = useContext(SpellerContext);
    return (
        <Container>
            {speller && speller.word && (
                <div>
                    <ZombieContainer>
                        {Array.from(speller.word.toUpperCase()).map((c) => {
                            if (alphabet.has(c)) {
                                return <img src={alphabet.get(c)?.thumb} alt={c} />;
                            }

                            return null;
                        })}
                    </ZombieContainer>
                    <div>
                        <b>Insert this code into your website:</b>
                        <br />
                        <code>
                            {Array.from(speller.word.toUpperCase()).map((c) => {
                                if (alphabet.has(c)) {
                                    return (
                                        <>
                                            &lt;img src=&quot;{alphabet.get(c)?.thumb}&quot; alt=&quot;{c}&quot; /&gt;
                                        </>
                                    );
                                }

                                return null;
                            })}
                            <br />
                            Zombie Letters from &lt;a href=&quot;https://e-zombie.com/&quot;&gt;e-zombie.com&lt;/a&gt;
                        </code>
                    </div>
                </div>
            )}
        </Container>
    );
};
