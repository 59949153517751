import React, { FC } from 'react';
import { SpellerContextProvider } from '../contexts/SpellerContext';
import { SpellerForm } from './SpellerForm';
import { SpellerOutput } from './SpellerOutput';

export const Speller: FC = () => {
    return (
        <SpellerContextProvider>
            <SpellerForm />
            <SpellerOutput />
        </SpellerContextProvider>
    );
};
