import React, { createContext, FC, useState } from 'react';

export type SpellerContextFields = {
    word: string;
    setWord: (newValue: string) => void;
};

export const SpellerContext = createContext<SpellerContextFields | null>(null);

export const SpellerContextProvider: FC = ({ children }) => {
    const [word, setWord] = useState('');
    return (
        <SpellerContext.Provider
            value={{
                word,
                setWord: (x) => {
                    setWord(x);
                },
            }}
        >
            {children}
        </SpellerContext.Provider>
    );
};
