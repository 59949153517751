import React, { FC } from 'react';
import styled from 'styled-components';
import { Speller } from './components/Speller';
import headerLogo from './images/spelling_header.jpg';

const AppContainer = styled.div`
    font-family: Arial, Helvetica, sans-serif;
`;

const AppHeader = styled.div`
    margin: 0 auto;
    text-align: center;
`;

const Footer = styled.footer`
    font-size: 0.8rem;
    margin: 2rem 0;
    text-align: center;
`;

export const App: FC = () => (
    <AppContainer>
        <AppHeader className="App-header">
            <img
                src={headerLogo}
                alt='Spelling With Zombies - Zombie Alphabet designed by L. Peralta. Simply type in a phrase and press "Go"'
            />
        </AppHeader>
        <Speller />
        <Footer>
            Zombies by <a href="https://www.lenperalta.com/">Len Peralta</a>. Code by{' '}
            <a href="https://dancoulter.com/">Dan Coulter</a>. Alphabet by the{' '}
            <a href="https://en.wikipedia.org/wiki/Latin_alphabet">Romans</a>.
        </Footer>
    </AppContainer>
);
